<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <sistema-tviacomunicacion-form
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import SistemaTviacomunicacionForm from '../components/SistemaTviacomunicacionForm'
import Data from './SistemaTviacomunicacionAddData'
import { toDate } from '@/utils/date'

export default {
  components: {
    SistemaTviacomunicacionForm
  },
  mixins: [formPageMixin],
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Añadir vía de comunicación'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      // caso especial de campos fecha -> convertir a Date si se modificaron
      if (this.formData.fcomprobacion && typeof(this.formData.fcomprobacion) === 'string') {
        this.formData.fcomprobacion = toDate(this.formData.fcomprobacion)
      }
      await Data.insert(this, this.formData, this.routeParams.idsistema)
      this.$appRouter.go(-1)
    },
  },
}
</script>
