export default {
  async insert (Vue, formData, idsistema) {
    const resp = await Vue.$api.call(
      'sistemaTviacomunicacion.insert',
      {
        values: {
          idsistema,
          idtviacomunicacion: formData.idtviacomunicacion,
          numero: formData.numero,
          n_serie: formData.n_serie,
          nabonado: formData.nabonado,
          idttransmisor: formData.idttransmisor,
          fcomprobacion: formData.fcomprobacion,
          observaciones: formData.observaciones,
          orden: 1,
        },
      }
    )
    return resp.data.result.dataset[0]
  },
}
