<template>
  <b10-base>
    <b10-autocomplete
      v-model="form.idtviacomunicacion"
      :items="tviasComunicacion"
      item-value="idtviacomunicacion"
      item-text="descripcion"
      label="Tipo de vía de comunicación"
      clearable
      :rules="formRules.idtviacomunicacion"
    />
    <v-text-field
      v-model="form.numero"
      clearable
      flat
      label="Número"
      :rules="formRules.numero"
    />
    <v-text-field
      v-model="form.n_serie"
      clearable
      flat
      label="Nº de serie"
      :rules="formRules.n_serie"
    />
    <v-text-field
      v-model="form.nabonado"
      clearable
      flat
      label="Nº de abonado"
      :rules="formRules.nabonado"
    />
    <b10-autocomplete
      v-model="form.idttransmisor"
      :items="ttransmisores"
      item-value="idttransmisor"
      item-text="descripcion"
      label="Tipo de transmisor"
      clearable
    />
    <b10-date-picker
      v-model="form.fcomprobacion"
      title="Última comprobación"
    />
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
      :rules="formRules.observaciones"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './SistemaTviacomunicacionFormData'

export default {
  mixins: [formMixin],
  data () {
    return {
      form: {
        idtviacomunicacion: null,
        numero: null,
        n_serie: null,
        nabonado: null,
        idttransmisor: null,
        fcomprobacion: null,
        observaciones: null,
      },
      formRules: {
        idtviacomunicacion: [
          v => !!v || 'Campo requerido'
        ],
      },
      ttransmisores: [],
      tviasComunicacion: [],
    }
  },
  async created () {
    const metadata = await Data.selectFormData(this, -1)
    this.addFormRulesAPI(metadata[1].columns)
    const [datasetTtransmisor] = await Data.selectTtransmisor(this)
    this.ttransmisores = datasetTtransmisor
    const [datasetTviacomunicacion] = await Data.selectTviacomunicacion(this)
    this.tviasComunicacion = datasetTviacomunicacion
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const [formData] = await Data.selectFormData(this, id)
      this.$set(this.form, 'idtviacomunicacion', formData.idtviacomunicacion)
      this.$set(this.form, 'numero', formData.numero)
      this.$set(this.form, 'n_serie', formData.n_serie)
      this.$set(this.form, 'nabonado', formData.nabonado)
      this.$set(this.form, 'idttransmisor', formData.idttransmisor)
      this.$set(this.form, 'fcomprobacion', formData.fcomprobacion)
      this.$set(this.form, 'observaciones', formData.observaciones)
    },
  }
}
</script>
