import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectTtransmisor (Vue) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
    const resp = await Vue.$api.call('ttransmisor.select', {
      filter: apiFilter
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectTviacomunicacion (Vue) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
    const resp = await Vue.$api.call('tviacomunicacion.select', {
      filter: apiFilter
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idsistema_tviacomunicacion', id)
    const resp = await Vue.$api.call('sistemaTviacomunicacion.select', {
      filter: apiFilter
    })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
}
